import { PublishAction } from './PublishAction'
import store from '@/store/store'

import { ActionNodeActionProxy } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions"
import {
    DelayIntervalType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/DelayIntervalType'
import {
    DelayDateType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/DelayDateType'
import {
    DelayPropertyType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/DelayPropertyType'
import { ProxyDelayIntervalType } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayTypes/ProxyDelayIntervalType'
import {
    ProxyDelayDateType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayTypes/ProxyDelayDateType'
import {
    ProxyDelayPropertyType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayTypes/ProxyDelayPropertyType'
import { isEmpty } from "lodash";

export class PublishActionProxy extends ActionNodeActionProxy {
    action: PublishAction

    constructor(action: PublishAction) {
        super()
        this.action = action

        if (!isEmpty(this.action.data.style) && this.action.data.style?.id) {
            this.action.data.style_id = this.action.data.style.id

            delete this.action.data.style
        }

        if (this.action.delay instanceof DelayIntervalType) {
            this.action.delay = new ProxyDelayIntervalType({ ...this.action.delay })
        } else if (this.action.delay instanceof DelayDateType) {
            this.action.delay = new ProxyDelayDateType({ ...this.action.delay })
        } else if (this.action.delay instanceof DelayPropertyType) {
            this.action.delay = new ProxyDelayPropertyType({ ...this.action.delay }, () => store.getters.propertiesList)
        }
    }

    getModel(): PublishAction {
        return this.action
    }

    isActionValid(): boolean {
        return !!this.action.targets.length && (!!this.action.message.text.length || !!this.action.message.attachments.length)
    }
}