











































































































































import { PublishActionProxy } from '../../logic/PublishActionProxy'
import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'
import PlaceholdersMixin from '@/mixins/PlaceholdersMixin'
import { Board, ChannelSlot } from '@/includes/types/Board.types'
import customMediaTypes from '@/components/MessageEditorCustomAttachments/Properties/customMediaTypes'
import { ChannelService } from "@/includes/services/ChannelService";
import { errorNotification } from "@/includes/services/NotificationService";
import { formatPostSlotTime } from "@/includes/logic/Slots/utils";
import SlotItem from "@/components/Slots/SlotItem.vue";
import ShowMoreSlots from "@/components/Slots/ShowMoreSlots.vue";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import {
  ProxyDelayIntervalType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayTypes/ProxyDelayIntervalType'
import {
  ProxyDelayDateType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayTypes/ProxyDelayDateType'
import {
  ProxyDelayPropertyType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayTypes/ProxyDelayPropertyType'
import {
  DelayType
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/DelayTypes/types'
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import DelayIntervalType
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/ui/Sidebar/DelayTypes/DelayIntervalType.vue'
import DelayDateType
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/ui/Sidebar/DelayTypes/DelayDateType.vue'
import DelayPropertyType
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/ui/Sidebar/DelayTypes/DelayPropertyType.vue'
import MessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import ProxyDelayFabric
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Branching/DelayNode/logic/Proxy/ProxyDelayFabric'
import PiTabs from "piramis-base-components/src/components/PiTabs.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator'
import { debounce, snakeCase } from 'lodash'

type DelayTabs = 'slot' | 'delay'

@Component({
  data() {
    return {
      DelayType,
      ProxyDelayPropertyType,
      ProxyDelayDateType,
      ProxyDelayIntervalType,
      EditorButtonsHelpView,
      customMediaTypes
    }
  },
  components: {
    ShowMoreSlots,
    SlotItem,
    PiTabs,
    DelayIntervalType,
    DelayDateType,
    DelayPropertyType,
    SelectInput,
    MessageEditorWithMediaInput
  },
  methods: {
    formatPostSlotTime
  }
})
export default class PublishActionSidebar extends Mixins(ModelSetter, InputSetups, PlaceholdersMixin) {
  @Prop() action!: PublishActionProxy

  debounceHandlePostTargetsInput = debounce(this.handlePostTargetsInput, 1000)

  slots: Array<ChannelSlot> | null = null

  slotsLoading = false

  tabs: Array<SelectOptionData> = [
    {
      label: this.$t('publish_action_slots_tab').toString(),
      value: 'slot',
      icon: {
        iconPack: 'material-icons',
        name: 'calendar_month'
      }
    },
    {
      label: this.$t('publish_action_delay_tab').toString(),
      value: 'delay',
      icon: {
        iconPack: 'material-icons',
        name: 'timer_10_select'
      }
    },
  ]

  currentTab: DelayTabs = !this.action.action.delay ? 'slot' : 'delay'

  onTabChange(tab: DelayTabs) {
    if (tab !== this.currentTab) {
      if (tab === 'slot') {
        this.action.action.delay = null
        this.handlePostTargetsInput()
      } else {
        this.swipeDelayTypeFn(DelayType.Interval)
      }
    }

    this.currentTab = tab
  }

  get style() {
    return this.action.action.data.style_id!
  }

  set style(id: number | null) {
    if (id === null) {
      this.action.action.data.style_id = null
    } else {
      this.action.action.data.style_id = id
    }
  }

  get stylesOptions(): Array<SelectOption> {
    const { activeBoard }: { activeBoard: Board } = this.$store.state.boardsState

    if (activeBoard && activeBoard.config.styles) {
      return Object.entries(activeBoard.config.styles).map<SelectOption>(([ key, style ]) => {
        return {
          label: key,
          value: style.id
        }
      })
    }

    return []
  }

  get delayTypeOptions(): Array<{ label: string, value: DelayType }> {
    return Object.values(DelayType).map((type) => {
      return { label: this.$t(`${ snakeCase(type) }_option`).toString(), value: type }
    })
  }

  handlePostTargetsInput() {
    this.slotsLoading = true

    if (this.$store.state.boardsState.activeBoard) {
      ChannelService.getChannelSlots('tg', {
        board_key: this.$store.state.boardsState.activeBoard.board,
        channel_ids: this.action.action.targets
      })
        .then(({ items }) => {
          this.slots = items
        })
        .catch(error => {
          errorNotification(error)

          this.slots = null
        })
        .finally(() => {
          this.slotsLoading = false
        })
    }
  }

  swipeDelayType(arg: FieldData): FieldData {
    arg.setter = (val) => this.swipeDelayTypeFn(val)

    return arg
  }

  swipeDelayTypeFn(type: DelayType): void {
    this.action.action.delay = ProxyDelayFabric.getDelayType(type, () => this.$store.getters.propertiesList)
  }

  created() {
    if (!this.action.action.delay) {
      this.handlePostTargetsInput()
    }
  }
}
